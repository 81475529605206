export default (state = {}, action = {}) => {
  if (!action.meta || !action.meta.location) {
    return state
  }

  const type = action.type

  if (type === "QUESTION") {
    if (action.payload.direction) {
      return action.payload.direction
    } else {
      return state
    }
  }

  if (type === "AGE") {
    if (action.payload.direction) {
      return action.payload.direction
    } else {
      return state
    }
  }

  if (type === "VIDEO") {
    if (action.payload.direction) {
      return action.payload.direction
    } else {
      return state
    }
  }

  return state
}
