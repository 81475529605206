import {createBrowserHistory} from "history"

import {combineReducers} from "redux"
import questionReducer from "./question-reducer"
import activeQuestionReducer from "./active-question-reducer"
// import titleReducer from './title'
import {connectRoutes} from "redux-first-router"
import {routes} from "../../routes"
import videoNavigation from "./videoNavigation"
import pageReducer from "./page"
import direction from "./direction"
import pageDirection from "./pageDirection"
import questionState from "./questionState"
import switchState from "./switchState"
import {createResponsiveStateReducer} from "redux-responsive"

const history = createBrowserHistory()
const {reducer: location} = connectRoutes(routes, history)

export {default as page} from "./page"

const allReducer = combineReducers({
  questions: questionReducer,
  activeQuestion: activeQuestionReducer,
  // title: titleReducer,
  page: pageReducer,
  direction: direction,
  pageDirection: pageDirection,
  questionState: questionState,
  switchState: switchState,
  videoNavigation,
  location,
  orientation: createResponsiveStateReducer({
    small: 0,
    medium: 640,
    large: 1024,
  }),
})

export default allReducer
