import React from "react"
import {connect} from "react-redux"
import {TransitionGroup, Transition} from "transition-group"
import universal from "react-universal-component"
import isLoading from "../selectors/isLoading"
import {fetchQuestions} from "../actions/actionCreators"
import Icon from "./spinner"

const Switcher = ({page, direction, isLoading, questions, switchState}) => (
  <TransitionGroup
    className={`switcher ${direction}`}
    duration={10}
    prefix="fade"
  >
    <Transition key={switchState} duration={0} enterDelay={0}>
      <UniversalComponent
        questions={questions}
        page={page}
        isLoading={isLoading}
      />
    </Transition>
  </TransitionGroup>
)

const UniversalComponent = universal(props => import(`./${props.page}`), {
  minDelay: 1000,
  chunkName: props => `./${props.page}`,
  loading: () => (
    <div className="spinner">
      <Icon width={200} fill="#fa2" />
    </div>
  ),
  error: () => <div className="notFound">PAGE NOT FOUND - 404</div>,
})

const mapState = state => {
  // Somehow, questionState has to be removed from the state, otherwise the
  // loading never stops (??)
  const {
    page,
    direction,
    switchState,
    questionState, // eslint-disable-line
    orientation, // eslint-disable-line
    questions,
    ...rest
  } = state

  return {
    page,
    direction,
    questionState,
    switchState,
    questions,
    isLoading: isLoading(rest),
  }
}

export default connect(mapState, {fetchQuestions})(Switcher)
