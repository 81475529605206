import React from "react"
import {connect} from "react-redux"
import {fetchQuestions} from "../actions/actionCreators"
import Switcher from "./Switcher"

class AppContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props.fetchQuestions()
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <Switcher />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    question: state.questions,
    videos: state.videos,
    active: state.location.payload.active,
    // activeIndex: state.location.payload.questionSelector,
    // activeQuestion: state.activeQuestion,
    age: state.location.payload.age,
    switchState: state.switchState,
  }
}

export default connect(mapStateToProps, {fetchQuestions})(AppContainer)
