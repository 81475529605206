// 1. the action (info what happend)
// 2. copy of current state
const initialState = {data: null, isFetching: false, error: null}
export const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_QUESTIONS_REQUEST":
      return {...state, isFetching: action.isFetching, error: action.error}
    case "FETCH_QUESTIONS_FAILURE":
      return {...state, isFetching: action.isFetching, error: action.error}
    case "FETCH_QUESTIONS_SUCCESS":
      return action.payload.data
    default:
      return state
  }
}

export default questionReducer
