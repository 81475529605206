import React, {Component} from "react"
import PropTypes from "prop-types"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import * as actionCreators from "../actions/actionCreators"

import AppContainer from "./AppContainer"

/*function mapStateToProps(state) {
  return {
    persons: state.persons,
    questions: state.questions,
    active: state.ActiveQuestion,
    // questionReducer: state.questionReducer
  }
}
*/
function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch)
}

const mapStateToProps = state => {
  return {
    location: state.location,
  }
}

class App extends Component {
  static propTypes = {
    location: PropTypes.object,
  }

  views = {
    HOME: <AppContainer />,
    QUESTION: <AppContainer />,
    VIDEO: <AppContainer />,
    AGE: <AppContainer />,
  }

  render() {
    return <div className="app">{this.views[this.props.location.type]}</div>
  }
}

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App)

export default ConnectedApp
