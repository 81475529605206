import {CURRENT, SET_VIDEO_NAVIGATION} from "../constants/VideoNavigationTypes"

const videoNavigation = (state = CURRENT, action) => {
  switch (action.type) {
    case SET_VIDEO_NAVIGATION:
      return action.filter
    default:
      return state
  }
}

export default videoNavigation
