export default (state = {}, action = {}) => {
  if (!action.meta || !action.meta.location) {
    return state
  }
  const type = action.type

  if (type === "QUESTION") {
    return action.payload.active
  }

  if (type === "AGE") {
    return action.payload.active
  }

  if (type === "VIDEO") {
    return action.payload.active
  }

  return state
}
