document.addEventListener("DOMContentLoaded", function() {
  function resizeGridItem(item) {
    let grid = document.getElementsByClassName("video__columns")[0]
    let rowHeight = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
    )
    let rowGap = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
    )
    let rowSpan = Math.ceil(
      (item.querySelector(".video-grid__item").getBoundingClientRect().height +
        rowGap) /
        (rowHeight + rowGap)
    )
    item.style.gridRowEnd = "span " + rowSpan
  }

  function resizeAllGridItems() {
    let allItems = document.getElementsByClassName("video-grid__selector")
    for (let x = 0; x < allItems.length; x++) {
      resizeGridItem(allItems[x])
    }
  }

  window.onload = resizeAllGridItems
  window.addEventListener("resize", resizeAllGridItems)
})
