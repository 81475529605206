function activeQuestionReducer(state = [], action) {
  switch (action.type) {
    case "VIDEO":
      return action.payload
    case "QUESTION":
      return action.payload
    case "AGE":
      return action.payload
    default:
      return state
  }
}

export default activeQuestionReducer
