import React from "react"
import ReactDOM from "react-dom"
// Import Components
// import App from './js/components/App'
// import Single from './js/components/Single'
// import VideoGrid from './js/components/VideoGrid'
import {Provider} from "react-redux"
// import react router deps
// import {Router, Route, IndexRoute, browserHistory} from 'react-router'
import configureStore from "./store"
import ConnectedApp from "./js/components/App"

const store = configureStore()

const render = element => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedApp />
    </Provider>,
    element
  )
}

export default render
