import {fetchQuestions} from "./js/actions/actionCreators"

export const routes = {
  HOME: {
    path: "/exhibition/",
  },
  QUESTION: {
    path: "/exhibition/:active/",
    thunk: fetchQuestions,
  },
  AGE: {
    path: "/exhibition/:active/:age/",
  },
  VIDEO: {
    path: "/exhibition/:active/:age/:single/",
    thunk: fetchQuestions,
  },
}
