import "react-app-polyfill/ie11"
import {createStore, applyMiddleware, compose} from "redux"
import {connectRoutes} from "redux-first-router"
import thunk from "redux-thunk"
// import actions

// import the root reducer
import allReducer from "./js/reducers/index"
import {routes} from "./routes"
import {responsiveStoreEnhancer} from "redux-responsive"

const {
  middleware: routerMiddleware,
  enhancer: routerEnhancer,

  initialDispatch,
} = connectRoutes(routes, {initialDispatch: false})

// Root store
export default function configureStore() {
  const store = createStore(
    allReducer,
    compose(
      routerEnhancer,
      responsiveStoreEnhancer,
      applyMiddleware(thunk, routerMiddleware),
      window.devToolsExtension ? window.devToolsExtension() : f => f
    )
  )

  initialDispatch()

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./js/reducers/index", () => {
      const nextRootReducer = require("./js/reducers/index")

      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
