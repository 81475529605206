import questionData from "../data/questionData"
export const fetchQuestions = () => async dispatch => {
  dispatch({
    type: "FETCH_QUESTIONS_REQUEST",
    isFetching: true,
    error: null,
  })

  return questionData
    .get(`/exhibition/data/`)
    .then(response => {
      dispatch({
        type: "FETCH_QUESTIONS_SUCCESS",
        isFetching: false,
        payload: response,
      })
    })
    .catch(err => {
      dispatch({
        type: "FETCH_QUESTIONS_FAILURE",
        isFetching: false,
        error: err,
      })
    })
}
