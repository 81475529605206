import "./main.scss"
// require('babel-core/register')
// require('babel-polyfill')
import "react-app-polyfill/ie11"
import "blissfuljs"
// import scrollmenu from './js/scrollmenu'
import _throttle from "lodash/throttle"
const $ = window.Bliss, // eslint-disable-line
  $$ = $.$ // eslint-disable-line

//import {Accordion} from './js/accordion'
import "js/writing"
import "js/video-grid"

// import 'js/video-player'
import render from "./foreveryoung"

$.ready().then(function() {
  let reactEl = document.getElementById("root")
  reactEl && render(reactEl)
  //  $$('[data-accordion-id]').forEach(el => new Accordion(el))

  //  $$('form').forEach(form => new AjaxForm(form))

  /*
   *  Scroll Menu
   */

  // let dataScrollAnchor = $('[data-scroll-anchor]')

  // if (dataScrollAnchor) {
  //   new scrollmenu(['.navigation a', '[data-scroll-anchor]'])
  // }

  /*
   *  Mobile Nav Toggle
   */
  $("[data-toggle-mobile-nav]").addEventListener("click", () => {
    $("[data-toggle-mobile-nav]").classList.toggle("white")
    document.body.classList.toggle("nav-is-open")
    $("#overlay").classList.toggle("open")
    document.body.classList.remove("nav-is-closing")

    if (!document.body.classList.contains("nav-is-open")) {
      document.body.classList.add("nav-is-closing")
    }
  })

  $$("[data-toggle-mobile-a]").forEach(function(e) {
    e.addEventListener("click", () => {
      $("[data-toggle-mobile-nav]").classList.toggle("white")
      document.body.classList.toggle("nav-is-open")
      $("#overlay").classList.toggle("open")
    })
  })

  // new scrollmenu(['.navigation a', '[data-scroll-anchor]'])

  let scrolledThreshold = 20

  window.addEventListener(
    "scroll",
    _throttle(() => {
      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add("scrolled")
      } else {
        document.body.classList.remove("scrolled")
      }
    }, 100)
  )

  window.addEventListener(
    "scroll",
    _throttle(() => {
      scrollFunction()
    }, 100)
  )

  let logoBig = $(".navigation__logo-big")
  let logoSmall = $(".navigation__logo-small")
  let header = $(".navigation")

  function scrollFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      header.classList.add("active")
      logoSmall.classList.add("active")
      logoBig.classList.remove("active")
      $(".eyecatcher").classList.add("active")
    } else {
      header.classList.remove("active")
      logoBig.classList.add("active")
      logoSmall.classList.remove("active")
      $(".eyecatcher").classList.remove("active")
    }
  }
})
