export default (state = "next", action = {}) => {
  if (!action.meta || !action.meta.location) {
    return state
  }
  const type = action.type
  const prevType = action.meta.location.prev.type
  if (type === prevType) {
    return state
  }
  if (type === "QUESTION") {
    return "back"
  }

  if (type === "QUESTION" && prevType === "VIDEO") {
    return "next"
  }

  if (type === "VIDEO" && prevType === "QUESTION") {
    return "next"
  }

  if (type === "AGE" && prevType === "AGE") {
    return "next"
  }

  return state
}
